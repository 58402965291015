import type { RecursivePartial } from '#types/utils'
import type { CheckoutConfig } from '#types/config/pages/checkout'

export default {
  contactFieldsOrder: (() => ['phone', 'email']) as unknown as CheckoutConfig['contactFieldsOrder'],
  hideLoyaltyDivider: true,
  loyaltyType: [
    'REWARD_CARD',
    'REWARD_CODE',
  ],
} satisfies RecursivePartial<CheckoutConfig>
